import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import MainNavigation from "../components/MainNavigation";
import styles from "./Home.module.css";
import AnimationIconMarket from "../components/AnimationIconMarket";
import LogoDrArz from "../components/LogoDrArz";

const Home = ({ className = "" }) => {
    const userId = localStorage.getItem("userId");
    const [coins, setCoins] = useState(0);
    const [username, setUsername] = useState("Unknown");
    const [avatar, setAvatar] = useState("");
    const [level, setLevel] = useState(1);
    const [loading, setLoading] = useState(true);
    const [lastAppUse, setLastAppUse] = useState(null);

    const navigate = useNavigate();

    const onIdrankContainerClick = useCallback(() => {
        navigate("/profile");
    }, [navigate]);

    const onRankingContainerClick = useCallback(() => {
        navigate("/ranking");
    }, [navigate]);

    const onMarketContainerClick = useCallback(() => {
        navigate("/marketmain");
    }, [navigate]);

    useEffect(() => {
        const fetchUserData = async () => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.BackButton.hide();
            }
            try {
                const response = await axios.get(`/api/users/${userId}`);
                if (response.data) {

                    setCoins(response.data.coins);
                    setUsername(response.data.username != null ? response.data.username : response.data.first_name);
                    setLevel(response.data.level);
                    setLastAppUse(response.data.lastTapUse);
                }

                const resAvatar = await axios.get(`/api/users/avatar/${userId}`, {
                    responseType: 'blob',
                });
                if (resAvatar.data) {
                    const objectUrl = URL.createObjectURL(resAvatar.data);
                    setAvatar(objectUrl);
                }
            } catch (error) {
                console.error('Failed to fetch user data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [userId]);
  return (
      <div className={styles.home}>
          <div className={[styles.marketRankWrapper, className].join(" ")}>
              <div className={styles.marketRank}>
                  <div className={styles.market} onClick={onMarketContainerClick}>
                      <AnimationIconMarket/>
                      <b className={styles.b}>بازار ارزها</b>
                  </div>
                  <div className={styles.rank}>
                      <div className={styles.idrank} onClick={onIdrankContainerClick}>
                          <h3 className={styles.milapahlavnian}>@{username}</h3>
                          <div className={styles.rankValueContainer}>
                              <div className={styles.rankValue}>
                                  <b className={styles.b1}>{level}</b>
                                  <div className={styles.div}>رتبه شما</div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className={styles.ranking} onClick={onRankingContainerClick}>
                      <div className={styles.icontrophyWrapper}>
                          <img
                              className={styles.icontrophy}
                              loading="lazy"
                              alt=""
                              src="/icontrophy.svg"
                          />
                      </div>
                      <b className={styles.b2}>برترینها</b>
                  </div>
              </div>
          </div>
          <main className={styles.content}>
              <section className={styles.conteiner}>
                  <img className={styles.lightBg01Icon} alt="" src="/lightbg01.svg"/>
                  <img className={styles.shapeBgIcon} alt="" src="/shapebg@2x.png"/>
                  <img
                      className={styles.doctorarzMen01Icon}
                      alt=""
                      src="/doctorarzmen01@2x.png"
                  />
                  <div className={[styles.doctorarzInfoWrapper, className].join(" ")}>
                      <div className={styles.doctorarzInfo}>
                          <div className={styles.doctorarzDescription}>
                              <div className={styles.doctorarzLogo}>
                                  <LogoDrArz/>
                              </div>
                              <h2 className={styles.h2}>اولین صرافی هوشمند ارزدیجیتال در ایران</h2>
                          </div>
                          <div className={styles.video}>
                              <div className={styles.watchVideo}>
                                  <div className={styles.getPoint}>
                                      <img
                                          className={styles.iconcoinpoint}
                                          loading="lazy"
                                          alt=""
                                          src="/iconcoinpoint.svg"
                                      />
                                      <div className={styles.points}>
                                          <div className={styles.div}>+{coins}</div>
                                      </div>
                                  </div>
                                  <div className={styles.videoGuide}>
                                      <b className={styles.b}>راهنمای کار با ربات</b>
                                  </div>
                                  <div className={styles.playButton}>
                                      <img
                                          className={styles.hiconBoldPlayCircle}
                                          loading="lazy"
                                          alt=""
                                          src="/hicon--bold--play-circle.svg"
                                      />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <img className={styles.groupIcon} alt=""/>
                  <MainNavigation/>
              </section>
          </main>
      </div>
  );
};

export default Home;
