// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MarketBuyDOGS_starsIcon__oSeOD {
  width: 453.9px;
  height: 561.4px;
  position: absolute;
  margin: 0 !important;
  top: 58px;
  left: -47.7px;
  object-fit: contain;
  z-index: 1;
}
.MarketBuyDOGS_dividerChild__fp-XA,
.MarketBuyDOGS_lightBgIcon__zFF7z {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.MarketBuyDOGS_dividerChild__fp-XA {
  top: 414px;
  left: 6px;
  border-radius: var(--br-5xl);
  width: 332px;
  height: 1px;
}
.MarketBuyDOGS_conteiner__ZqRA2,
.MarketBuyDOGS_divider__r52BK {
  position: absolute;
  top: 0;
  left: 8px;
  width: 338px;
  height: 587px;
}
.MarketBuyDOGS_conteiner__ZqRA2 {
  top: 72px;
  left: 0;
  border-radius: var(--br-21xl);
  width: 100%;
  height: 670px;
  z-index: 3;
}
.MarketBuyDOGS_topnavigationParent__xNir5 {
  align-self: stretch;
  height: 742px;
  position: relative;
}
.MarketBuyDOGS_marketBuydogs__NLO6b {
  width: 100%;
  position: relative;
  background-color: var(--color-gray-300);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: normal;
  letter-spacing: normal;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MarketBuyDOGS.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,oBAAoB;EACpB,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,UAAU;AACZ;AACA;;EAEE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,SAAS;EACT,4BAA4B;EAC5B,YAAY;EACZ,WAAW;AACb;AACA;;EAEE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,YAAY;EACZ,aAAa;AACf;AACA;EACE,SAAS;EACT,OAAO;EACP,6BAA6B;EAC7B,WAAW;EACX,aAAa;EACb,UAAU;AACZ;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,uCAAuC;EACvC,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;EAC3B,mBAAmB;EACnB,sBAAsB;AACxB","sourcesContent":[".starsIcon {\n  width: 453.9px;\n  height: 561.4px;\n  position: absolute;\n  margin: 0 !important;\n  top: 58px;\n  left: -47.7px;\n  object-fit: contain;\n  z-index: 1;\n}\n.dividerChild,\n.lightBgIcon {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n.dividerChild {\n  top: 414px;\n  left: 6px;\n  border-radius: var(--br-5xl);\n  width: 332px;\n  height: 1px;\n}\n.conteiner,\n.divider {\n  position: absolute;\n  top: 0;\n  left: 8px;\n  width: 338px;\n  height: 587px;\n}\n.conteiner {\n  top: 72px;\n  left: 0;\n  border-radius: var(--br-21xl);\n  width: 100%;\n  height: 670px;\n  z-index: 3;\n}\n.topnavigationParent {\n  align-self: stretch;\n  height: 742px;\n  position: relative;\n}\n.marketBuydogs {\n  width: 100%;\n  position: relative;\n  background-color: var(--color-gray-300);\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: flex-start;\n  line-height: normal;\n  letter-spacing: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"starsIcon": `MarketBuyDOGS_starsIcon__oSeOD`,
	"dividerChild": `MarketBuyDOGS_dividerChild__fp-XA`,
	"lightBgIcon": `MarketBuyDOGS_lightBgIcon__zFF7z`,
	"conteiner": `MarketBuyDOGS_conteiner__ZqRA2`,
	"divider": `MarketBuyDOGS_divider__r52BK`,
	"topnavigationParent": `MarketBuyDOGS_topnavigationParent__xNir5`,
	"marketBuydogs": `MarketBuyDOGS_marketBuydogs__NLO6b`
};
export default ___CSS_LOADER_EXPORT___;
