import { useState, useCallback } from "react";
import InvoiceDOGS from "./InvoiceDOGS";
import PortalPopup from "./PortalPopup";
import PropTypes from "prop-types";
import styles from "./Div.module.css";

const Div = ({ className = "" }) => {
  const [isInvoiceDOGSOpen, setInvoiceDOGSOpen] = useState(false);

  const openInvoiceDOGS = useCallback(() => {
    setInvoiceDOGSOpen(true);
  }, []);

  const closeInvoiceDOGS = useCallback(() => {
    setInvoiceDOGSOpen(false);
  }, []);

  return (
    <>
      <div className={[styles.div, className].join(" ")}>
        <h3 className={styles.dogs}>تعداد DOGS مد نظر جهت فروش به ما</h3>
        <div className={styles.inputParent}>
          <div className={styles.input}>
            <div className={styles.inputLabel}>
              <h3 className={styles.dogs1}>DOGS</h3>
            </div>
            <div className={styles.inputValue}>602,000,000</div>
            <div className={styles.logo}>
              <img
                className={styles.image5Icon}
                loading="lazy"
                alt=""
                src="/image-5-11@2x.png"
              />
            </div>
          </div>
          <div className={styles.input1}>
            <div className={styles.wrapper}>
              <h3 className={styles.h3}>ریال</h3>
            </div>
            <h2 className={styles.h2}>۱۰،۵۶۰،۰۰۰</h2>
            <div className={styles.iconsrialWrapper}>
              <img
                className={styles.iconsrial}
                loading="lazy"
                alt=""
                src="/iconsrial.svg"
              />
            </div>
          </div>
        </div>
        <button className={styles.buttonconfirm} onClick={openInvoiceDOGS}>
          <div className={styles.glass} />
          <b className={styles.b}>تایید</b>
        </button>
      </div>
      {isInvoiceDOGSOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeInvoiceDOGS}
        >
          <InvoiceDOGS onClose={closeInvoiceDOGS} />
        </PortalPopup>
      )}
    </>
  );
};

Div.propTypes = {
  className: PropTypes.string,
};

export default Div;
