import Topnavigation from "../components/Topnavigation";
import Div from "../components/Div";
import FrameComponent9 from "../components/FrameComponent9";
import styles from "./MarketBuyDOGS.module.css";

const MarketBuyDOGS = () => {
  return (
    <div className={styles.marketBuydogs}>
      <img className={styles.starsIcon} alt="" src="/stars2@2x.png" />
      <div className={styles.topnavigationParent}>
        <Topnavigation />
        <div className={styles.conteiner}>
          <Div />
          <div className={styles.divider}>
            <img className={styles.lightBgIcon} alt="" src="/lightbg6.svg" />
            <img
              className={styles.dividerChild}
              loading="lazy"
              alt=""
              src="/line-11.svg"
            />
          </div>
          <FrameComponent9 />
        </div>
      </div>
    </div>
  );
};

export default MarketBuyDOGS;
