import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import FrameComponent6 from "../components/FrameComponent6";
import MainNavigation from "../components/MainNavigation";
import styles from "./Earn.module.css";
import {toast} from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';

const Earn = () => {
    const userId = localStorage.getItem("userId");
    const [referralLink, setReferralLink] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [referredUsers, setReferredUsers] = useState([]);
    const [referredCount, setReferredCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [coins, setCoins] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();

    if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.BackButton.onClick(() => {
            window.history.back();
        });
    }

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const {data} = await axios.get(`/api/users/${userId}`);
                setCoins(data.coins);
                setReferralCode(data.referralCode);
                setReferralLink(`https://t.me/doctorarz_bot?start=${data.referralCode}`);
                await fetchReferralData(data.referralCode, 1);
            } catch (err) {
                console.error('Failed to fetch user details', err);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [userId]);

    const fetchReferralData = async (referralCode, page) => {
        try {
            console.log(`Fetching page ${page}`);
            const {data} = await axios.get(`/api/referrals/${referralCode}?page=${page}`);
            const usersWithAvatars = await Promise.all(
                data.users.map(async (user) => {
                    try {
                        const resAvatar = await axios.get(`/api/users/avatar/${user.userId}`, {
                            responseType: 'blob',
                        });
                        if (resAvatar.data) {
                            const objectUrl = URL.createObjectURL(resAvatar.data);
                            return {...user, avatar: objectUrl};
                        }
                    } catch (err) {
                        console.error('Failed to fetch avatar', err);
                    }
                    return user;
                })
            );
            setReferredUsers(prevUsers => [...prevUsers, ...usersWithAvatars]);
            setReferredCount(data.count);
            if (usersWithAvatars.length === 0 || usersWithAvatars.length < 10) {
                setHasMore(false);
            }
        } catch (err) {
            console.error('Failed to fetch referral details', err);
            toast.error('Failed to fetch referral details');
        }
    };

    const loadMoreReferrals = () => {
        console.log('Loading more referrals');
        const nextPage = page + 1;
        setPage(nextPage);
        fetchReferralData(referralLink.split('=')[1], nextPage);
    };

    return (
        <div className={styles.earn}>
            <div className={styles.watchVideoParent}>
                <div className={styles.watchVideo}>
                    <div className={styles.getPoint}>
                        <img
                            className={styles.iconcoinpoint}
                            loading="lazy"
                            alt=""
                            src="/iconcoinpoint1.svg"
                        />
                        <div className={styles.wrapper}>
                            <div className={styles.div}>+۱۰۰</div>
                        </div>
                    </div>
                    <div className={styles.container}>
                        <b className={styles.b}>عضویت در کانال رسمی</b>
                    </div>
                </div>
                <div className={styles.frameWrapper}>
                    <div className={styles.frameParent}>
                        <div className={styles.frame}>
                            <h3 className={styles.h3}>کسب درآمد</h3>
                        </div>
                        <div className={styles.buttonBack}>
                            <img
                                className={styles.fiRrAngleSmallRightIcon}
                                loading="lazy"
                                alt=""
                                src="/firranglesmallright.svg"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <img className={styles.coverFrontIcon} alt="" src="/cover-front.svg"/>
            <div className={styles.conteinerParent}>
                <div className={styles.conteiner}>
                    <img className={styles.starsIcon} alt="" src="/stars@2x.png"/>
                    <FrameComponent6 referralCode={referralCode} referralLink={referralLink}/>
                    <div className={styles.div1}>
            <span
                className={styles.span}
            >{`با به اشتراک گذاری لینک اختصاصی خود،‌ در کانال های تلگرامی و گروه های دوستان، علاوه بر `}</span>
                        <b>کسب ۱۰٪ مشارکت در فروش</b>
                        <span
                            className={styles.span}
                        >{` کاربرانی که شما به دکتر.ارز معرفی می کنید، `}</span>
                        <b>شما و کاربر معرفی شده هر یک ۱ امتیاز هدیه</b>
                        <span className={styles.span}> دریافت خواهید کرد.</span>
                    </div>
                    <div className={styles.frameGroup}>
                        <div className={styles.lightBgParent}>
                            <img className={styles.lightBgIcon} alt="" src="/lightbg3.svg"/>
                            <div className={styles.income}>
                                <div className={styles.head}>
                                    <div className={styles.updateTime}>
                                        <img
                                            className={styles.iconsrefresh}
                                            loading="lazy"
                                            alt=""
                                            src="/iconsrefresh.svg"
                                        />
                                        <div className={styles.div2}>بروزرسانی ۲۰:۴۱</div>
                                    </div>
                                    <h3 className={styles.h31}>درآمد کسب شده</h3>
                                    <img
                                        className={styles.iconsearnings}
                                        loading="lazy"
                                        alt=""
                                        src="/iconsearnings.svg"
                                    />
                                </div>
                                <div className={styles.totalIncome}>
                                    <button className={styles.buttonWithdraw}>
                                        <div className={styles.div3}>برداشت</div>
                                    </button>
                                    <b className={styles.b1}>
                                        <span>{`۱،۶۵۰،۰۰۰ `}</span>
                                        <span className={styles.span3}>تومان</span>
                                    </b>
                                </div>
                                <div className={styles.separator}/>
                                <div className={styles.div4}>
                                    <div className={styles.tokenEarns}>
                                        <div className={styles.content}>
                                            <h3 className={styles.h32}>۱۰۵</h3>
                                            <div className={styles.div5}>امتیاز کسب شده</div>
                                        </div>
                                        <div className={styles.buttonCircle}>
                                            <img
                                                className={styles.iconcoinpoint1}
                                                loading="lazy"
                                                alt=""
                                                src="/iconcoinpoint-1.svg"
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.comitions}>
                                        <div className={styles.content1}>
                                            <div className={styles.div6}>
                                                <span>{`۱،۶۵۰،۰۰۰ `}</span>
                                                <span className={styles.span4}>تومان</span>
                                            </div>
                                            <div className={styles.div7}>پورسانت از فروش</div>
                                        </div>
                                        <div className={styles.buttonCircle1}>
                                            <img
                                                className={styles.iconcoin}
                                                loading="lazy"
                                                alt=""
                                                src="/iconcoin2.svg"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.div8}>
                                    *مجموع درآمد کسب شده بدون در نظر گرفتن نقد شده یا نشده میباشد.
                                </div>
                            </div>
                        </div>
                        <div className={styles.frameContainer}>
                            <div className={styles.buttonCircleParent}>
                                <div className={styles.buttonCircle2}>
                                    <img
                                        className={styles.fiRrAngleSmallDownIcon}
                                        alt=""
                                        src="/firranglesmalldown.svg"
                                    />
                                    <div className={styles.frameDiv}>
                                        <div className={styles.div9}>جدیدترین</div>
                                    </div>
                                </div>
                                <div className={styles.referralsCountContainerWrapper}>
                                    <div className={styles.referralsCountContainer}>
                                        <div className={styles.numbers}>
                                            <div className={styles.div10}>{referredCount} نفر</div>
                                        </div>
                                        <div className={styles.div11}>معرفی شدگان</div>
                                        <div className={styles.iconsuserSalaryWrapper}>
                                            <img
                                                className={styles.iconsuserSalary}
                                                loading="lazy"
                                                alt=""
                                                src="/iconsusersalary.svg"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {referredUsers.length > 0 ? (
                                <InfiniteScroll
                                    dataLength={referredUsers.length}
                                    next={loadMoreReferrals}
                                    hasMore={hasMore}
                                    loader={<div className="loader" key={0}>Loading ...</div>}
                                    scrollableTarget="scrollableDiv"
                                    endMessage={
                                        <p style={{textAlign: 'center'}}>
                                            <b>No more referrals</b>
                                        </p>
                                    }
                                >
                                    <div className={styles.friendsList}>
                                        {referredUsers.map(user => (
                                            <div className={styles.friendslist}>
                                                <div className={styles.content2}>
                                                    <div className={styles.div12}>
                                                        <span className={styles.span5}>{`۶۰،۰۰۰ `}</span>
                                                        <span className={styles.span6}>تومان</span>
                                                    </div>
                                                    <div className={styles.pointDetails}>
                                                        <img
                                                            className={styles.iconcoinpoint2}
                                                            loading="lazy"
                                                            alt=""
                                                            src="/iconcoinpoint-2.svg"
                                                        />
                                                        <div className={styles.div13}>{user.level} امتیاز</div>
                                                    </div>
                                                </div>
                                                <div className={styles.nameDate}>
                                                    <div className={styles.nameid}>
                                                        <div className={styles.ehsanpahlv}>@{user.username}</div>
                                                        <div className={styles.div14}>{user.first_name}</div>
                                                    </div>
                                                    <div className={styles.div15}>{user.createdAt}</div>
                                                </div>
                                                <img
                                                    className={styles.avatarIcon}
                                                    loading="lazy"
                                                    alt=""
                                                    src={user.avatar}
                                                />
                                            </div>
                                        ))}

                                    </div>
                                </InfiniteScroll>
                            ) : (
                                <div className="frens-data">
                                    <div className="frens-profile">
                                        <div className="frens-profile-data white">
                                            <h4>You don't have referrals 😢</h4>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <MainNavigation/>
        </div>
    );
};

export default Earn;
