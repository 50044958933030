import {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import RankItemDetails from "../components/RankItemDetails";
import SpinAnimation from "../components/SpinAnimation";
import styles from "./Ranking.module.css";
import MainNavigation from "../components/MainNavigation";

const Ranking = () => {
    const navigate = useNavigate();

    const onButtonBackContainerClick = useCallback(() => {
        navigate("/home");
    }, [navigate]);

    return (
        <div className={styles.ranking}>
            <img className={styles.starsIcon} alt="" src="/Stars.png"/>
            <div className={styles.content}>
                <div className={styles.rankingListContainer}>
                    <div className={styles.rankingListHeader}>
                        <div className={styles.lastUpdateContainer}>
                            {/*<div className={styles.rankingListHeader}>*/}
                            {/*    <img*/}
                            {/*        className={styles.iconsrefresh}*/}
                            {/*        loading="lazy"*/}
                            {/*        alt=""*/}
                            {/*        src="/iconsrefresh.svg"*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<div className={styles.div}>آخرین بروزرسانی ۲۰:۴۱</div>*/}
                        </div>
                    </div>
                    <div className={styles.rankingTitleContainer}>
                        <div className={styles.rankingTitleRow}>
                            <b className={styles.b}>برترین ها</b>
                        </div>
                        <div
                            className={styles.buttonBack}
                            onClick={onButtonBackContainerClick}
                        >
                            <img
                                className={styles.fiRrAngleSmallRightIcon}
                                loading="lazy"
                                alt=""
                                src="/firranglesmallright.svg"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.listContainer}>
                <div className={styles.conteiner}>
                    <RankItemDetails/>
                    <div className={styles.rank410}>
                        <div className={styles.rankinglist}>
                            <div className={styles.rank}>
                                <img
                                    className={styles.fiRrCaretUpIcon}
                                    loading="lazy"
                                    alt=""
                                    src="/firrcaretup-1.svg"
                                />
                                <div className={styles.div1}>۴</div>
                            </div>
                            <div className={styles.idpoint}>
                                <div className={styles.point}>
                                    <img
                                        className={styles.iconcoinpoint}
                                        loading="lazy"
                                        alt=""
                                        src="/iconcoinpoint-2.svg"
                                    />
                                    <div className={styles.div2}>۹۶۳</div>
                                </div>
                                <div className={styles.pooriyabisun}>@pooriyabisun</div>
                            </div>
                            <img
                                className={styles.avatarIcon}
                                loading="lazy"
                                alt=""
                                src="/avatar-31@2x.png"
                            />
                        </div>
                        <div className={styles.rankinglist1}>
                            <div className={styles.rank}>
                                <img
                                    className={styles.fiRrCaretUpIcon}
                                    loading="lazy"
                                    alt=""
                                    src="/firrcaretdown-1.svg"
                                />
                                <div className={styles.div1}>۵</div>
                            </div>
                            <div className={styles.idpoint}>
                                <div className={styles.point1}>
                                    <img
                                        className={styles.iconcoinpoint}
                                        loading="lazy"
                                        alt=""
                                        src="/iconcoinpoint-2.svg"
                                    />
                                    <div className={styles.div2}>۸۲۱</div>
                                </div>
                                <div className={styles.migbigmanIran}>@migbigman_iran</div>
                            </div>
                            <img
                                className={styles.avatarIcon}
                                loading="lazy"
                                alt=""
                                src="/avatar-4@2x.png"
                            />
                        </div>
                        <div className={styles.rankinglist1}>
                            <div className={styles.rank}>
                                <img
                                    className={styles.fiRrCaretUpIcon}
                                    loading="lazy"
                                    alt=""
                                    src="/firrcaretup-1.svg"
                                />
                                <div className={styles.div1}>۶</div>
                            </div>
                            <div className={styles.idpoint}>
                                <div className={styles.point2}>
                                    <img
                                        className={styles.iconcoinpoint}
                                        loading="lazy"
                                        alt=""
                                        src="/iconcoinpoint-2.svg"
                                    />
                                    <div className={styles.div2}>۸۱۹</div>
                                </div>
                                <div className={styles.bitjib}>@bitjib</div>
                            </div>
                            <img
                                className={styles.avatarIcon}
                                loading="lazy"
                                alt=""
                                src="/avatar-5@2x.png"
                            />
                        </div>
                        <div className={styles.rankinglist1}>
                            <div className={styles.rank3}>
                                <img className={styles.fiRrCaretUpIcon2} alt=""/>
                                <div className={styles.div7}>۷</div>
                            </div>
                            <div className={styles.idpoint}>
                                <div className={styles.point3}>
                                    <img
                                        className={styles.iconcoinpoint}
                                        loading="lazy"
                                        alt=""
                                        src="/iconcoinpoint-2.svg"
                                    />
                                    <div className={styles.div2}>۷۶۰</div>
                                </div>
                                <div className={styles.migbigmanIran}>@joonevardahe80</div>
                            </div>
                            <img
                                className={styles.avatarIcon}
                                loading="lazy"
                                alt=""
                                src="/avatar-6@2x.png"
                            />
                        </div>
                        <div className={styles.rankinglist4}>
                            <div className={styles.rank3}>
                                <img className={styles.fiRrCaretUpIcon2} alt=""/>
                                <div className={styles.div7}>۸</div>
                            </div>
                            <div className={styles.idpoint}>
                                <div className={styles.point1}>
                                    <img
                                        className={styles.iconcoinpoint}
                                        loading="lazy"
                                        alt=""
                                        src="/iconcoinpoint-2.svg"
                                    />
                                    <div className={styles.div2}>۶۲۰</div>
                                </div>
                                <div className={styles.pahlavanianm}>@pahlavanianm</div>
                            </div>
                            <img
                                className={styles.avatarIcon}
                                loading="lazy"
                                alt=""
                                src="/avatar-7@2x.png"
                            />
                        </div>
                        <div className={styles.rankinglist1}>
                            <div className={styles.rank}>
                                <img
                                    className={styles.fiRrCaretUpIcon}
                                    alt=""
                                    src="/firrcaretdown-1.svg"
                                />
                                <div className={styles.div1}>۹</div>
                            </div>
                            <div className={styles.idpoint}>
                                <div className={styles.point}>
                                    <img
                                        className={styles.iconcoinpoint}
                                        loading="lazy"
                                        alt=""
                                        src="/iconcoinpoint-2.svg"
                                    />
                                    <div className={styles.div2}>۵۸۹</div>
                                </div>
                                <div className={styles.yadeayamikedash}>@yadeayamikedash</div>
                            </div>
                            <img
                                className={styles.avatarIcon}
                                loading="lazy"
                                alt=""
                                src="/avatar-8@2x.png"
                            />
                        </div>
                        <div className={styles.rankinglist1}>
                            <div className={styles.rank6}>
                                <img
                                    className={styles.fiRrCaretUpIcon}
                                    alt=""
                                    src="/firrcaretup-1.svg"
                                />
                                <div className={styles.div1}>۱۰</div>
                            </div>
                            <div className={styles.idpoint}>
                                <div className={styles.point3}>
                                    <img
                                        className={styles.iconcoinpoint}
                                        loading="lazy"
                                        alt=""
                                        src="/iconcoinpoint-2.svg"
                                    />
                                    <div className={styles.div2}>۴۶۰</div>
                                </div>
                                <div className={styles.drzahrajaberi}>@drzahrajaberi</div>
                            </div>
                            <img
                                className={styles.avatarIcon}
                                loading="lazy"
                                alt=""
                                src="/avatar-9@2x.png"
                            />
                        </div>
                    </div>
                    <SpinAnimation/>
                </div>
                <MainNavigation/>
            </div>
        </div>
    );
};

export default Ranking;
