import Friendslist from "./Friendslist";
import PropTypes from "prop-types";
import styles from "./FrameComponent9.module.css";

const FrameComponent9 = ({ className = "" }) => {
  return (
    <div className={[styles.stepsContainerParent, className].join(" ")}>
      <div className={styles.stepsContainer}>
        <div className={styles.parent}>
          <div className={styles.div}>مراحل فروش:</div>
          <div className={styles.walletContainer}>
            <ul className={styles.wallet}>
              <li className={styles.wallet1}>
                بعد از وارد نمودن تعداد مورد نظر جهت فروش و زدن دکمه تایید،
                فرآیند اتصال به کیف پول Wallet شما انجام و صورتحساب فروش به شما
                نمایش داده میشود .
              </li>
              <li>
                در صورت تایید مراحل و پیش فاکتور، مراحل انتقال و واریز به حساب
                بانکی ( کارت ، شبا ) انجام میشود. نتیجه تراکنش و انتقال مبلغ را
                میتوانید در تاریخچه فروش ها مشاهده کنید.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.historyHeaderParent}>
        <div className={styles.historyHeader}>
          <div className={styles.buttonCircle}>
            <img
              className={styles.fiRrAngleSmallDownIcon}
              alt=""
              src="/firranglesmalldown.svg"
            />
            <div className={styles.newHistoryLabel}>
              <div className={styles.div1}>جدیدترین</div>
            </div>
          </div>
          <div className={styles.historyList}>
            <div className={styles.historyListHeader}>
              <div className={styles.numbers}>
                <div className={styles.div2}>۲ مورد یافت شد</div>
              </div>
              <div className={styles.div3}>تاریخچه فروش</div>
              <div className={styles.historyIcon}>
                <img
                  className={styles.iconshistory}
                  loading="lazy"
                  alt=""
                  src="/iconshistory.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.transactions}>
          <Friendslist
            prop="۲،۳۶۰،۰۰۰ "
            tON="$TON"
            prop1="200."
            image3="/image-3@2x.png"
          />
          <Friendslist
            propMinWidth="60px"
            prop="۵۸۶،۰۰۰ "
            propWidth="128px"
            tON="$HMSTR"
            propColor="#ffe177"
            propMinWidth1="unset"
            propWidth1="42px"
            propMinWidth2="47px"
            propFlex="1"
            prop1="1,550."
            propBorderRadius="32px"
            image3="/hamstercoin-1@2x.png"
            propBorderRadius1="unset"
          />
        </div>
      </div>
    </div>
  );
};

FrameComponent9.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent9;
