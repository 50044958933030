import PropTypes from "prop-types";
import styles from "./Topnavigation.module.css";

const Topnavigation = ({ className = "" }) => {
  return (
    <div className={[styles.topnavigation, className].join(" ")}>
      <div className={styles.priceContainerWrapper}>
        <div className={styles.priceContainer}>
          <h3 className={styles.h3}>۳۰۲ ریال</h3>
          <a className={styles.a}>هر واحد</a>
        </div>
      </div>
      <div className={styles.logo}>
        <img
          className={styles.image5Icon}
          loading="lazy"
          alt=""
          src="/image-51@2x.png"
        />
      </div>
      <div className={styles.titleContainerWrapper}>
        <div className={styles.titleContainer}>
          <div className={styles.titleInner}>
            <div className={styles.titleFlex}>
              <div className={styles.wrapper}>
                <h3 className={styles.h31}>داگز</h3>
              </div>
              <a className={styles.dogs}>$DOGS</a>
            </div>
          </div>
          <div className={styles.buttonBack}>
            <img
              className={styles.fiRrAngleSmallRightIcon}
              loading="lazy"
              alt=""
              src="/firranglesmallright.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Topnavigation.propTypes = {
  className: PropTypes.string,
};

export default Topnavigation;
