import PropTypes from "prop-types";
import styles from "./FrameComponent2.module.css";

const FrameComponent2 = ({ className = "", firstname, lastname, username, coins, level, avatar }) => {
  return (
    <div className={[styles.minibartitlecontrolParent, className].join(" ")}>
      <div className={styles.frameParent}>
        <div className={styles.frameWrapper}>
          <img
            className={styles.frameChild}
            loading="lazy"
            alt=""
            src="/group-9.svg"
          />
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.frameContainer}>
            <div className={styles.parent}>
              <div className={styles.div}>{firstname} {lastname}</div>
              <div className={styles.userRankInfo}>
                <b className={styles.b}>{level}</b>
                <div className={styles.div1}>رتبه شما</div>
              </div>
            </div>
          </div>
          <div className={styles.avatar}>
            <div className={styles.avatarBackground}>
              <img
                className={styles.lightBgIcon}
                loading="lazy"
                alt=""
                src="/lightbg.svg"
              />
              <img
                className={styles.portote1Icon}
                alt=""
                src={avatar}
              />
              <div className={styles.avatarBackgroundChild} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;
