import { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./Friendslist.module.css";

const Friendslist = ({
  className = "",
  propMinWidth,
  prop,
  propWidth,
  tON,
  propColor,
  propMinWidth1,
  propWidth1,
  propMinWidth2,
  propFlex,
  prop1,
  propBorderRadius,
  image3,
  propBorderRadius1,
}) => {
  const divStyle = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  const nameIDStyle = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  const tONStyle = useMemo(() => {
    return {
      color: propColor,
      minWidth: propMinWidth1,
      width: propWidth1,
    };
  }, [propColor, propMinWidth1, propWidth1]);

  const separatorStyle = useMemo(() => {
    return {
      minWidth: propMinWidth2,
      flex: propFlex,
    };
  }, [propMinWidth2, propFlex]);

  const logoStyle = useMemo(() => {
    return {
      borderRadius: propBorderRadius,
    };
  }, [propBorderRadius]);

  const image3IconStyle = useMemo(() => {
    return {
      borderRadius: propBorderRadius1,
    };
  }, [propBorderRadius1]);

  return (
    <div className={[styles.friendslist, className].join(" ")}>
      <div className={styles.content}>
        <div className={styles.div} style={divStyle}>
          <span className={styles.span}>{prop}</span>
          <span className={styles.span1}>ریال</span>
        </div>
        <div className={styles.numbers}>
          <div className={styles.div1}>در دست انجام</div>
        </div>
      </div>
      <div className={styles.userInfo}>
        <div className={styles.nameid} style={nameIDStyle}>
          <div className={styles.ton} style={tONStyle}>
            {tON}
          </div>
          <div className={styles.separator} style={separatorStyle}>
            <span className={styles.span}>{prop1}</span>
            <span className={styles.span3}>00</span>
          </div>
          <div className={styles.div2}>فروش</div>
        </div>
        <div className={styles.bankInfo}>
          <div className={styles.div3}>واریز به بانک ملت</div>
          <div className={styles.dateSeparator}>.</div>
          <div className={styles.div4}>۱۲ تیر ۱۴۰۳، ۱۳:۵۰</div>
        </div>
      </div>
      <div className={styles.logo} style={logoStyle}>
        <img
          className={styles.image3Icon}
          loading="lazy"
          alt=""
          src={image3}
          style={image3IconStyle}
        />
      </div>
    </div>
  );
};

Friendslist.propTypes = {
  className: PropTypes.string,
  prop: PropTypes.string,
  tON: PropTypes.string,
  prop1: PropTypes.string,
  image3: PropTypes.string,

  /** Style props */
  propMinWidth: PropTypes.any,
  propWidth: PropTypes.any,
  propColor: PropTypes.any,
  propMinWidth1: PropTypes.any,
  propWidth1: PropTypes.any,
  propMinWidth2: PropTypes.any,
  propFlex: PropTypes.any,
  propBorderRadius: PropTypes.any,
  propBorderRadius1: PropTypes.any,
};

export default Friendslist;
