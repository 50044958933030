import PropTypes from "prop-types";
import styles from "./InvoiceDOGS.module.css";

const InvoiceDOGS = ({ className = "", onClose }) => {
  return (
    <div className={[styles.invoicedogs, className].join(" ")}>
      <section className={styles.dateContainerParent}>
        <div className={styles.dateContainer}>
          <div className={styles.imageContainerParent}>
            <div className={styles.imageContainer}>
              <div className={styles.div}>۱۲ تیر ۱۴۰۳، ۱۳:۵۰</div>
            </div>
            <img
              className={styles.image3Icon}
              loading="lazy"
              alt=""
              src="/image-32@2x.png"
            />
            <div className={styles.wrapper}>
              <div className={styles.div1}>پیش فاکتور</div>
            </div>
          </div>
          <div className={styles.currencyType}>
            <div className={styles.dogs20000Wrapper}>
              <div className={styles.div}>
                <span>
                  <span>{`$DOGS `}</span>
                  <span className={styles.span}>200.</span>
                </span>
                <span className={styles.span}>
                  <span className={styles.span2}>00</span>
                </span>
              </div>
            </div>
            <div className={styles.currencyPrice}>
              <div className={styles.currencyPriceValue} />
            </div>
            <div className={styles.div2}>تعداد ارز برای فروش</div>
          </div>
        </div>
        <div className={styles.currencyPriceLabel}>
          <div className={styles.totalAmount}>
            <div className={styles.div3}>
              <span>۹۶۰،۵۰۰</span>
              <span className={styles.span3}>ریال</span>
            </div>
          </div>
          <div className={styles.totalAmountLabelWrapper}>
            <div className={styles.currencyPriceValue} />
          </div>
          <div className={styles.div4}>قیمت هر واحد ارز</div>
        </div>
        <div className={styles.termsAgreement}>
          <div className={styles.agreementCheckbox}>
            <div className={styles.totalAmount}>
              <div className={styles.div5}>
                <span>۹۶۰،۰۰۰،۵۰۰</span>
                <span className={styles.span3}>ریال</span>
              </div>
            </div>
            <div className={styles.totalAmountLabelWrapper}>
              <div className={styles.currencyPriceValue} />
            </div>
            <div className={styles.parent}>
              <div className={styles.div6}>مجموع مبلغ</div>
              <div className={styles.div7}>توضیحات:</div>
            </div>
          </div>
          <div className={styles.div8}>
            در صورت تایید مراحل و پیش فاکتور، مراحل انتقال و واریز به حساب بانکی
            ( کارت ، شبا ) انجام میشود. نتیجه تراکنش و انتقال مبلغ را میتوانید
            در تاریخچه فروش ها مشاهده کنید.
          </div>
        </div>
        <div className={styles.pricePerUnitLabel}>
          <div className={styles.div9}>
            شرایط و مقررات را مطالعه و قبول دارم
          </div>
        </div>
        <div className={styles.toggleBlue}>
          <div className={styles.off} />
          <div className={styles.on} />
        </div>
        <button className={styles.buttonconfirm}>
          <div className={styles.glass} />
          <b className={styles.b}>تایید</b>
        </button>
      </section>
      <div className={styles.buttonBack}>
        <img className={styles.xmarkIcon} alt="" src="/xmark.svg" />
      </div>
    </div>
  );
};

InvoiceDOGS.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

export default InvoiceDOGS;
