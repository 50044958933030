import PropTypes from "prop-types";
import styles from "./FrameComponent6.module.css";
import { toast } from 'react-toastify';
import React, {useState} from 'react';


const FrameComponent6 = ({ className = "", referralLink, referralCode }) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    setIsCopied(true);
    toast.success('لینک معرفی کپی شد.');
  };

  return (
    <div className={[styles.referralLinkParent, className].join(" ")}>
      <div className={styles.referralLink}>
        <div className={styles.head}>
          <button className={styles.rewards}>
            <div className={styles.addFriend}>
              <div className={styles.div}>دعوت از دوستان</div>
              <img
                className={styles.iconsplusSmall}
                alt=""
                src="/iconsplussmall1.svg"
              />
            </div>
          </button>
          <h3 className={styles.h3}>لینک اختصاصی شما</h3>
          <img
            className={styles.iconsplusSmall}
            loading="lazy"
            alt=""
            src="/iconsuseradd.svg"
          />
        </div>
        <div className={styles.link}>
          <div className={styles.httpstmedoctorarz5505350}>
            <span className={styles.httpstmedoctorarz}>
              https://t.me/doctorarz/
            </span>
            <b>{referralCode}</b>
          </div>
          <div className={styles.rewards1}>
            <div className={styles.copy}>
              <input type="hidden" value={referralLink} readOnly/>
              <div onClick={copyToClipboard} className={styles.div1}>
                کپی
                <input className={styles.iconsclone} type="checkbox"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.div2}>
          با معرفی دوستان خود درآمد بی قید و شرط و مادام العمر کسب کنید
        </div>
      </div>
    </div>
  );
};

FrameComponent6.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent6;
