import {useCallback} from "react";
import FrameComponent7 from "../components/FrameComponent7";
import {useNavigate} from "react-router-dom";
import styles from "./MarketMain.module.css";
import MainNavigation from "../components/MainNavigation";

const MarketMain = () => {
    const navigate = useNavigate();

    const onCardtokenContainerClick = useCallback(() => {
        // Please sync "Market-Buy/TON" to the project
    }, []);

    const onCardtokenContainerClick1 = useCallback(() => {
        // Please sync "Market-Buy/DOTCOIN" to the project
    }, []);

    const onCardtokenContainerClick2 = useCallback(() => {
        // Please sync "Market-Buy/HMSTR" to the project
    }, []);

    const onCardtokenContainerClick3 = useCallback(() => {
        navigate("/");
    }, [navigate]);

    const onCardtokenContainerClick4 = useCallback(() => {
        // Please sync "Market-Buy/NOT" to the project
    }, []);

    const onCardtokenContainerClick5 = useCallback(() => {
        // Please sync "Market-Buy/TAPS" to the project
    }, []);

    const onCardtokenContainerClick6 = useCallback(() => {
        // Please sync "Market-Buy/Major" to the project
    }, []);

    const onCardtokenContainerClick7 = useCallback(() => {
        // Please sync "Market-Buy/CEXIO" to the project
    }, []);

    const onCardtokenContainerClick8 = useCallback(() => {
        // Please sync "Market-Buy/Blum" to the project
    }, []);

    const onCardtokenContainerClick9 = useCallback(() => {
        // Please sync "Market-Buy/Second" to the project
    }, []);

    const onCardtokenContainerClick10 = useCallback(() => {
        // Please sync "Market-Buy/Memefi" to the project
    }, []);

    const onCardtokenContainerClick11 = useCallback(() => {
        // Please sync "Market-Buy/PAWS" to the project
    }, []);

    const onCardtokenContainerClick12 = useCallback(() => {
        // Please sync "Market-Buy/Rabitcoin" to the project
    }, []);

    const onCardtokenContainerClick13 = useCallback(() => {
        // Please sync "Market-Buy/YES" to the project
    }, []);

    const onCardtokenContainerClick14 = useCallback(() => {
        // Please sync "Market-Buy/BOOM" to the project
    }, []);

    return (
        <div className={styles.marketMain}>
            <div className={styles.lightBgParent}>
                <img className={styles.lightBgIcon} alt="" src="/lightbg4.svg"/>
                <img className={styles.starsIcon} alt="" src="/StarsMarket.png"/>
            </div>
            <FrameComponent7/>
            <main className={styles.conteinerParent}>
                <section className={styles.conteiner}>
                    <div className={styles.table}>
                        <div className={styles.row}>
                            <div
                                className={styles.cardtoken}
                                onClick={onCardtokenContainerClick}
                            >
                                <div className={styles.contents}>
                                    <div className={styles.logo}>
                                        <img
                                            className={styles.tokenImgIcon}
                                            loading="lazy"
                                            alt=""
                                            src="/token-img@2x.png"
                                        />
                                    </div>
                                    <div className={styles.content}>
                                        <b className={styles.b}>تون کوین</b>
                                        <div className={styles.ton}>$TON</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.row1}>
                            <div
                                className={styles.cardtoken1}
                                onClick={onCardtokenContainerClick1}
                            >
                                <div className={styles.contents1}>
                                    <div className={styles.logo1}>
                                        <img
                                            className={styles.image5Icon}
                                            loading="lazy"
                                            alt=""
                                            src="/image-5@2x.png"
                                        />
                                    </div>
                                    <div className={styles.content1}>
                                        <b className={styles.b1}>دات کوین</b>
                                        <div className={styles.dotcoin}>$DOTCOIN</div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={styles.cardtoken2}
                                onClick={onCardtokenContainerClick2}
                            >
                                <div className={styles.contents2}>
                                    <div className={styles.logo}>
                                        <img
                                            className={styles.tokenImgIcon1}
                                            loading="lazy"
                                            alt=""
                                            src="/token-img-1@2x.png"
                                        />
                                    </div>
                                    <div className={styles.content2}>
                                        <b className={styles.b2}>همستر کامبت</b>
                                        <div className={styles.hmstr}>$HMSTR</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.row2}>
                            <div
                                className={styles.cardtoken3}
                                onClick={onCardtokenContainerClick3}
                            >
                                <div className={styles.contents3}>
                                    <div className={styles.logo1}>
                                        <img
                                            className={styles.tokenImgIcon2}
                                            loading="lazy"
                                            alt=""
                                            src="/token-img-2@2x.png"
                                        />
                                    </div>
                                    <div className={styles.content3}>
                                        <b className={styles.b3}>داگز</b>
                                        <div className={styles.dogs}>$DOGS</div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={styles.cardtoken4}
                                onClick={onCardtokenContainerClick4}
                            >
                                <div className={styles.contents4}>
                                    <div className={styles.logo}>
                                        <img
                                            className={styles.tokenImgIcon3}
                                            loading="lazy"
                                            alt=""
                                            src="/token-img-3@2x.png"
                                        />
                                    </div>
                                    <div className={styles.content2}>
                                        <b className={styles.b}>نات کوین</b>
                                        <div className={styles.ton}>$NOT</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.row2}>
                            <div
                                className={styles.cardtoken5}
                                onClick={onCardtokenContainerClick5}
                            >
                                <div className={styles.contents5}>
                                    <div className={styles.logo}>
                                        <img
                                            className={styles.tokenImgIcon1}
                                            loading="lazy"
                                            alt=""
                                            src="/token-img-4@2x.png"
                                        />
                                    </div>
                                    <div className={styles.content2}>
                                        <b className={styles.b5}>تپ سوآپ</b>
                                        <div className={styles.taps}>$TAPS</div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={styles.cardtoken6}
                                onClick={onCardtokenContainerClick6}
                            >
                                <div className={styles.contents6}>
                                    <div className={styles.logo}>
                                        <img
                                            className={styles.tokenImgIcon3}
                                            loading="lazy"
                                            alt=""
                                            src="/token-img-5@2x.png"
                                        />
                                    </div>
                                    <div className={styles.content2}>
                                        <b className={styles.b6}>میجر</b>
                                        <div className={styles.major}>$MAJOR</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.row2}>
                            <div
                                className={styles.cardtoken7}
                                onClick={onCardtokenContainerClick7}
                            >
                                <div className={styles.contents7}>
                                    <div className={styles.logo1}>
                                        <img
                                            className={styles.tokenImgIcon2}
                                            loading="lazy"
                                            alt=""
                                            src="/token-img-6@2x.png"
                                        />
                                    </div>
                                    <div className={styles.content2}>
                                        <b className={styles.b2}>سی‌ای‌اکس آیو</b>
                                        <div className={styles.cexio}>$CEXIO</div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={styles.cardtoken8}
                                onClick={onCardtokenContainerClick8}
                            >
                                <div className={styles.contents8}>
                                    <div className={styles.logo1}>
                                        <img
                                            className={styles.tokenImgIcon2}
                                            loading="lazy"
                                            alt=""
                                            src="/token-img-7@2x.png"
                                        />
                                    </div>
                                    <div className={styles.content8}>
                                        <b className={styles.b8}>بلوم</b>
                                        <div className={styles.blum}>$BLUM</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.row5}>
                            <div
                                className={styles.cardtoken9}
                                onClick={onCardtokenContainerClick9}
                            >
                                <div className={styles.contents9}>
                                    <div className={styles.logo1}>
                                        <img
                                            className={styles.tokenImgIcon2}
                                            loading="lazy"
                                            alt=""
                                            src="/image-5-1@2x.png"
                                        />
                                    </div>
                                    <div className={styles.content1}>
                                        <b className={styles.b9}>تایم فارم</b>
                                        <div className={styles.second}>$SECOND</div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={styles.cardtoken10}
                                onClick={onCardtokenContainerClick10}
                            >
                                <div className={styles.contents10}>
                                    <div className={styles.logo1}>
                                        <img
                                            className={styles.tokenImgIcon2}
                                            loading="lazy"
                                            alt=""
                                            src="/token-img-8@2x.png"
                                        />
                                    </div>
                                    <div className={styles.content1}>
                                        <b className={styles.b10}>میم‌فای</b>
                                        <div className={styles.memefi}>$MEMEFI</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.row2}>
                            <div
                                className={styles.cardtoken11}
                                onClick={onCardtokenContainerClick11}
                            >
                                <div className={styles.contents7}>
                                    <div className={styles.logo11}>
                                        <img
                                            className={styles.image7Icon}
                                            loading="lazy"
                                            alt=""
                                            src="/image-7@2x.png"
                                        />
                                    </div>
                                    <div className={styles.content2}>
                                        <b className={styles.b2}>پنجه بیبی دوج</b>
                                        <div className={styles.blum}>$PAWS</div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={styles.cardtoken12}
                                onClick={onCardtokenContainerClick12}
                            >
                                <div className={styles.contents12}>
                                    <div className={styles.logo1}>
                                        <img
                                            className={styles.tokenImgIcon9}
                                            loading="lazy"
                                            alt=""
                                            src="/token-img-9@2x.png"
                                        />
                                    </div>
                                    <div className={styles.content1}>
                                        <b className={styles.b12}>راکی رابیت</b>
                                        <div className={styles.rabitcoin}>$RABITCOIN</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.row5}>
                            <div
                                className={styles.cardtoken13}
                                onClick={onCardtokenContainerClick13}
                            >
                                <div className={styles.contents13}>
                                    <div className={styles.logo1}>
                                        <img
                                            className={styles.image5Icon}
                                            loading="lazy"
                                            alt=""
                                            src="/image-5-2@2x.png"
                                        />
                                    </div>
                                    <div className={styles.content1}>
                                        <b className={styles.b13}>یس کوین</b>
                                        <div className={styles.yescoin}>$YESCOIN</div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={styles.cardtoken14}
                                onClick={onCardtokenContainerClick14}
                            >
                                <div className={styles.contents8}>
                                    <div className={styles.logo1}>
                                        <img
                                            className={styles.tokenImgIcon10}
                                            loading="lazy"
                                            alt=""
                                            src="/token-img-10@2x.png"
                                        />
                                    </div>
                                    <div className={styles.content14}>
                                        <b className={styles.b14}>بوم</b>
                                        <div className={styles.boom}>$BOOM</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.updateTime}>
                            <img
                                className={styles.iconsrefresh}
                                loading="lazy"
                                alt=""
                                src="/iconsrefresh.svg"
                            />
                            <div className={styles.div}>
                                قیمت ها زنده | بروزرسانی در ۲۰:۴۱
                            </div>
                        </div>
                    </div>
                </section>
                <MainNavigation/>
            </main>
        </div>
    );
};

export default MarketMain;
