import ChatInputContainer from "../components/ChatInputContainer";
import List from "../components/List";
import styles from "./Support.module.css";
import MainNavigation from "../components/MainNavigation";

const Support = () => {
    return (
        <div className={styles.support}>
            <img className={styles.lightBgIcon} alt="" src="/lightbg1.svg"/>
            <div className={styles.content}>
                <div className={styles.callHistoryContainer}>
                    <div className={styles.buttonCircle}>
                        <img
                            className={styles.fiRrCallHistoryIcon}
                            loading="lazy"
                            alt=""
                            src="/firrcallhistory.svg"
                        />
                        <div className={styles.div}>۱۶۲۰۵۰</div>
                        <div className={styles.wrapper}>
                            <div className={styles.div1}>خط داغ</div>
                        </div>
                    </div>
                    <div className={styles.supportContainer}>
                        <div className={styles.supportButtonContainer}>
                            <div className={styles.supportButton}>
                                <b className={styles.b}>پشتیبانی</b>
                            </div>
                            <div className={styles.buttonBack}>
                                <img
                                    className={styles.fiRrAngleSmallRightIcon}
                                    loading="lazy"
                                    alt=""
                                    src="/firranglesmallright.svg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <main className={styles.chatContainer}>
                <section className={styles.conteiner}>
                    <img className={styles.shapeBgIcon} alt=""/>
                    <ChatInputContainer/>
                    <img className={styles.gradientcoverIcon} alt=""/>
                    <div className={styles.div2}>
                        <p
                            className={styles.p}
                        >{`با استفاده از سامانه پشتیبانی تیکت دکتر ارز میتوانید درخواست های خود را برای دپارتمان های دکتر ارز ارسال کنید. `}</p>
                        <p className={styles.p}>
                            همچنین میتوانید با چت زنده با ربات دکتر ارز در ارتباط باشید.
                        </p>
                    </div>
                    <List/>
                    <MainNavigation/>
                </section>
            </main>
        </div>
    );
};

export default Support;
